import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Header from './header'
import OutlineBox from './outline-box'
import styled, { css, injectGlobal } from 'react-emotion'
import colors from '../constants/colors'

import '../assets/index.css'

injectGlobal`
 a {
   color: ${colors.darkGray};
   text-decoration: none;
 }
`

const Main = styled.div`
  background: linear-gradient(#fff 60%, #bfcac9);
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: hidden;
`

const ContentHolder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  @media (min-width: 450px) {
    max-width: 1000px;
  }

  @media (min-width: 1000px) {
    max-width: 1300px;
  }
`

const backButton = css`
  background: hsla(0, 0%, 0, 0.8);
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 2em;
  filter: blur(1px);
  & > a {
    transform: none;
    color: rgba(0, 0, 0, 0.7)
  }

  @media (min-width: 800px) {
    background: none;
  }
`

const Layout = ({ children, isRoot }) => (
	<Main>
		<Helmet
			title={'skininthega.me'}
			meta={[
				{ name: 'description', content: 'Sample' },
				{ name: 'keywords', content: 'sample, something' }
			]}
		/>
		<Header />

		<ContentHolder>
			<Content>{children}</Content>
		</ContentHolder>
		{!isRoot && (
			<OutlineBox className={backButton}>
				<Link to={'/'}>Return</Link>
			</OutlineBox>
		)}
	</Main>
)

Layout.propTypes = {
	children: PropTypes.func,
	isRoot: PropTypes.bool.isRequired
}

export default Layout
