import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled, { css } from 'react-emotion'
import LinkContainer from '../components/link-container'
import Layout from '../components/layout'

const staticLinks = [{ label: 'about', to: 'about' }]

const homeImage = css`
  position: absolute;
  left: 50%;
  width: 50%;
  top: 20px;

  @media (min-width: 450px) {
    top: 30%;
  }
`

const linkContainerStyles = css`
  position: relative;
  left: 10%;
  top: 20%;
  filter: blur(1px);

  @media (max-width: 840px) {
    top: 50%;
    height: 200%;
  }
`

const LinkScroller = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
  }
`

const IndexPage = ({ data }) => {
	const dynamicLinks = staticLinks.concat(
		data.allPrismicCollection.edges.map(e => ({
			label: e.node.data.title.text,
			to: e.node.slugs[0]
		}))
	)
  
	return (
		<Layout isRoot>
			<LinkScroller>
				<LinkContainer links={dynamicLinks} className={linkContainerStyles} />
			</LinkScroller>
			<div className={homeImage}>
				<Image
					fluid={data.prismicHome.data.home_image.localFile.childImageSharp.fluid}
					imgStyle={{ maxHeight: '50vh', width: 'unset' }}
				/>
			</div>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
  query indexPage {
    prismicHome {
      data {
        home_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPrismicCollection {
      edges {
        node {
          slugs
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`
