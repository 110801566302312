import React from "react";
import { Link } from "gatsby";
import { css } from "react-emotion";
import OutlineBox from "./outline-box";

const unfocused = css`
  opacity: 0.2;
`;

const focused = css`
  & a {
    transform: translateX(-10%);
    filter: blur(2px);
    /* background: rgba(255,255,255,0.7); */
    padding: 0 2px;
  }
`;

const linkStyle = css`
  font-size: 2em;
  opacity: 1;
  padding: 20px 0;
`;

const innerLinkStyle = css`
  background: rgba(255, 255, 255, 0.7);
  background: linear-gradient(to right, transparent, white 90%, transparent);
  padding-right: 20px;
  border-radius: 5px;
`;

const isFocusedStyle = ({ focusedIdx, idx }) =>
  focusedIdx === idx ? focused : focusedIdx === undefined ? "" : unfocused;

class LinkContainer extends React.Component {
  state = {
    focusedIdx: undefined
  }

  handleLinkEnter = e => {
    this.setState({
      focusedIdx: Number(e.currentTarget.id.slice(5))
    });
  };

  handleLinkLeave = () => {
    this.setState({ focusedIdx: undefined });
  };

  render() {
    const { links, className } = this.props;
    const { focusedIdx } = this.state;
    return (
      <div className={className}>
        {links.map((l, idx) => (
          <div
            key={l.label}
            id={"link-" + idx}
            className={linkStyle + " " + isFocusedStyle({ focusedIdx, idx })}
            onMouseEnter={this.handleLinkEnter}
            onMouseLeave={this.handleLinkLeave}
          >
            <OutlineBox style={focusedIdx === idx ? { right: 0 } : undefined}>
              <Link to={l.to} className={innerLinkStyle}>
                {l.label}
              </Link>
            </OutlineBox>
          </div>
        ))}
      </div>
    );
  }
}

export default LinkContainer;
