import styled from 'react-emotion'
import colors from '../constants/colors'
import borderImage from '../assets/border.png'

const OutlineBox = styled.span`
  border: 4px dashed ${colors.outline};
  border-image: url(${borderImage}) 3 repeat;
  display: inline-block;
  line-height: 2rem;

  & > * {
    position: relative;
    display: block;
    transform: translate(-15px, -5%);
  }
`

export default OutlineBox
