import styled from 'react-emotion'
import breakpoints from '../constants/breakpoints'

const MediaBreak = styled.div`
	@media (min-width: ${props => breakpoints[props.maxWidth]|| '10000px'}) {
		display: none;
	}
`


export default MediaBreak