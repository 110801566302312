import { css } from 'react-emotion'

export const fontSize = (minSize, maxSize, minRes, maxRes) =>
	css`
	font-size: ${minSize}em;
	
	@media (min-width: ${minRes}px) {
		font-size: calc(${minSize}em + ${maxSize - minSize} * ((100vw - ${minRes / 16}em) / ${(maxRes - minRes) / 16}));
	}

	@media (min-width: ${maxRes}px) {
		font-size: ${maxSize}em;
	}
	`

export const flex = css`display: flex; justify-content: space-around`