import React from "react";
import Link from "gatsby-link";
import styled from "react-emotion";

import colors from "../constants/colors";
import MediaBreak from "./media-break";
import { fontSize } from "../utils/css";

const Word = styled.span`
  background: ${props => (props.active ? colors.highlight : "transparent")};
  display: inline-block;
  margin-left: 15px;
  line-height: 1em;
  position: relative;

  @media (max-width: 450px) {
    display: fixed;
    left: -40vw;
  }

  @media (max-width: 950px) {
    &::after {
      box-shadow: none !important;
      transition: none;
    }
  }

  &::after {
    /* transition: box-shadow 10s; */
    box-shadow: ${props =>
      new Array(props.active ? 55 : 30)
        .fill(0)
        .map(
          (_, i) =>
            `${props.active ? 2 * i : 0}px ${100 +
              i * (props.active ? 20 : 30)}px 0 0 ${i % 2 ? "white" : "black"}`
        )
        .join(", ")};
    position: absolute;
    left: 0;
    content: "";
    height: 10px;
    top: -12px;
    width: 100%;
    mix-blend-mode: color-dodge;
  }
`;

const Header = styled.div`
  ${fontSize(2, 4, 450, 800)};
  position: absolute;
  right: 20%;
  top: 100px;
  height: 85px;
  filter: blur(1px);
  background: white;
  transition: color 0.5s ease;
  cursor: default;
  /* animation: 8s slowBlur infinite; */

  &::after {
    background: white;
    mix-blend-mode: difference;
    color: #a3ffec;
    content: attr(alt);
    height: ${({ barHeight }) => barHeight}px;
    filter: blur(1px);
    left: 34px;
    top: 23%;
    overflow: hidden;
    position: absolute;
    transform: rotate(${({ rotation }) => rotation}deg) scaleX(1.05);
    z-index: 10;
  }

  &::before {
    background: white;
    color: white;
    content: attr(alt);
    height: 8px;
    left: 0;
    top: 27%;
    overflow: hidden;
    position: absolute;
  }
`;

class HeaderComponent extends React.Component {
  state = {};

  componentDidMount() {
    this.setState({
      highlightedIndex: Math.floor(Math.random() * 4),
      barHeight: Math.round(Math.random() * 35 + 1),
      rotation: Math.random() * 8 - 4
    });
  }

  render() {
    const { highlightedIndex, barHeight, rotation } = this.state;

    return (
      <Header alt="skininthega.me" barHeight={barHeight} rotation={rotation}>
        <Word active={highlightedIndex >= 0}>skin</Word>
        <MediaBreak maxWidth="xs" />
        <Word active={highlightedIndex >= 1}>in</Word>
        <Word active={highlightedIndex >= 2}>the</Word>
        <Word active={highlightedIndex >= 3}>ga.me</Word>
      </Header>
    );
  }
}

export default HeaderComponent;
